/* eslint-disable promise/prefer-await-to-callbacks */
import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import getToken from './getToken'
import { globalErrorHandler } from './globalErrorHandler'

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_GRAPHQL_API_PREFIX_URL}`

const httpLink = createHttpLink({
  uri: baseUrl,
})

const errorLink = onError((error: ErrorResponse) => globalErrorHandler(error))

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken() || headers?.authorization

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
      'x-custom-lang': 'fr',
    },
  }
})

const graphqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  // add errorLink, authLink before httpLink otherwise those will not get called
  link: from([errorLink, authLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy: 'cache-and-network',
      //fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      //fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export default graphqlClient
